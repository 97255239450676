import SearchIcon from '@mui/icons-material/Search';
import { AnimatePresence, motion } from 'framer-motion';
import { useEffect, useRef } from 'react';
import { useRouter } from 'next/router';
import ReactFocusLock from 'react-focus-lock';
import styles from './modal.module.css';

const dropIn = {
  hidden: {
    y: '-100vh',
    opacity: 0,
  },
  visible: {
    y: '0',
    opacity: 1,
    transition: {
      duration: 0.1,
      type: 'spring',
      damping: 25,
      stiffness: 500,
    },
  },
  exit: {
    y: '100vh',
    opacity: 0,
  },
};

type Props = { open: boolean; setOpen: (a: boolean) => boolean };

export default function SearchModal({ open, setOpen }: Props) {
  const router = useRouter();
  const searchBar = useRef<HTMLInputElement>();

  useEffect(() => searchBar?.current?.focus(), [open]);

  return (
    <AnimatePresence
      // Disable any initial animations on children that
      // are present when the component is first rendered
      initial={false}
      // Only render one component at a time.
      // The exiting component will finish its exit
      // animation before entering component is rendered
      exitBeforeEnter
      // Fires when all exiting nodes have completed animating out
      onExitComplete={() => null}>
      {open && (
        <motion.div className={styles.backdrop} onClick={() => setOpen(false)} initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
          <motion.div
            onClick={(e) => e.stopPropagation()} // Prevent click from closing modal
            className={styles.modal}
            variants={dropIn}
            initial="hidden"
            animate="visible"
            exit="exit">
            <div className="searchbar rounded-t-full w-full">
              <ReactFocusLock>
                <div className="bg-th-background-secondary rounded-full">
                  <form
                    className="flex"
                    onSubmit={(e) => {
                      e.preventDefault();
                      router.push(`/resources?q=${searchBar.current.value.toLowerCase()}`);
                      setOpen(false);
                    }}>
                    <input ref={searchBar} className="text-xl text-th-primary-light lg:text-4xl rounded-full bg-opacity-0 bg-black p-4 lg:p-10 w-11/12" placeholder="Type something..." />
                    <button type="submit">
                      <SearchIcon color="primary" className="my-auto mr-4 text-8xl" fontSize="large" />
                    </button>
                  </form>
                </div>
                <ul className="my-8 text-white opacity-70 text-center">
                  <li>Search by words in title/subtitle/description</li>
                  <li>Use "" for exact phrase e.g. "cool map"</li>
                  <li>Exclude results using "-" e.g. "-word" will exclude all results containing "word"</li>
                  <button type="button" onClick={() => setOpen(false)}>
                    Close
                  </button>
                </ul>
              </ReactFocusLock>
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}
